import React from 'react';
import { connect } from 'react-redux';
import { getDic } from '../assets/i18n/dictionary';
import { insertNewAccess, getSystemActions } from '../services/accessService';
import * as navigationHistoryService from '../services/navigationHistoryService';
import {
  getCampaignsApi,
  addNewCampaignApi,
  updateCampaignApi,
  deleteCampaignApi,
} from '../services/campaignService';
import {
  setChosenCampaign
} from "../store/actions";
import { getNumberOfTagsFromMessage, getTemplateList } from '../services/whatsappService';
import { createCampaignModel, convertFormToCampaignObject, convertCampaignObjectToForm } from '../models/campaignModel';
import { validateForm } from '../utils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import SendIcon from '@material-ui/icons/Send';
import CopyIcon from '@material-ui/icons/FileCopy';
//Imports de components
import {
  MenuButton,
  BackButton,
  Alert,
  Loading,
  Container,
  FormRow,
  FormColumn,
  GridRow,
  GridCell,
  TextMedium,
  Button,
  Dialog,
  InputFieldOutlined,
  TextSmall,
  InfiniteScroll,
  SelectList,
  Modal,
  Card
} from '../components';

const CampaignsRegisterPage = (props) => {
  const [state, setState] = React.useState(true);

  //Alert control
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  //Loading control
  const [loadingMessage, setLoadingMessage] = React.useState(getDic("carregando"));
  const [openLoading, setOpenLoading] = React.useState(true);

  //Dialog control
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [dialogConfirmKey, setDialogConfirmKey] = React.useState("");
  const [dialogConfirmKeyErrorMessage, setDialogConfirmKeyErrorMessage] = React.useState("");

  //Modal Control
  const [openModal, setOpenModal] = React.useState(false);

  const [campaignsList, setCampaignsList] = React.useState([]);
  const [whatsappTemplateList, setWhatsappTemplateList] = React.useState([]);
  const [chosenCampaign, setChosenCampaign] = React.useState(null);
  const [newCampaignForm, setNewCampaignForm] = React.useState([]);

  React.useEffect(() => {
    //Bloqueio caso o usuário não seja admin
    if (!props.user.isAdmin) {
      props.history.goBack();
    }

    var helpButton = document.getElementById("launcher");
    if (helpButton) {
      helpButton.style.visibility = 'hidden';
    }

    getPageData();
    insertNewAccess(props.match.url, "", getSystemActions().accessPage);

    return () => {
      var helpButton = document.getElementById("launcher");
      if (helpButton) {
        helpButton.style.visibility = 'visible';
      }
      insertNewAccess(props.match.url, "", getSystemActions().exitPage);
    }
  }, []);

  const getPageData = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("carregando"));

    getCampaignsApi()
      .then(res => {
        setCampaignsList(res);
      })
      .catch(() => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      });

    getTemplateList()
      .then(res => {
        setWhatsappTemplateList(res);
      })
      .catch(() => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("sem-conexao"));
      })
      .finally(() => {
        setOpenLoading(false);
      });
  }

  const onChangeHandler = (index, value) => {
    var _newForm = newCampaignForm;
    _newForm[index].value = value;
    if (_newForm[index].dbReference === 'templateName') {
      _newForm[4].value = whatsappTemplateList.find(t => { return t.value === value }).message;
    }
    setNewCampaignForm(_newForm);
    setState(state === true ? false : true);
  }

  const resetCampaignForm = () => {
    const _newCampaign = createCampaignModel();
    const _newForm = convertCampaignObjectToForm(_newCampaign, whatsappTemplateList);
    setNewCampaignForm(_newForm);
  }

  const createNewCampaign = () => {
    const _newCampaign = createCampaignModel();
    const _newForm = convertCampaignObjectToForm(_newCampaign, whatsappTemplateList);
    setNewCampaignForm(_newForm);
    setOpenModal(true);
  }

  const editCampaign = (campaign) => {
    const _newForm = convertCampaignObjectToForm(campaign, whatsappTemplateList);
    setNewCampaignForm(_newForm);
    setOpenModal(true);
  }

  const copyCampaign = (campaign) => {
    var _newForm = convertCampaignObjectToForm(campaign, whatsappTemplateList);
    const _idIndex = _newForm.findIndex(f => f.dbReference === 'id');
    _newForm[_idIndex].value = null;
    setNewCampaignForm(_newForm);
    setOpenModal(true);
  }

  const inactiveCampaign = () => {
    setOpenLoading(true);
    setLoadingMessage(getDic("salvando"));

    deleteCampaignApi(chosenCampaign.id)
      .then(res => {
        const _newCampaignList = [];
        campaignsList.forEach(item => {
          if (item.id !== chosenCampaign.id) {
            _newCampaignList.push(item);
          }
        });
        _newCampaignList.sort((a, b) => {
          return (a.createDate > b.createDate) ? 1 : ((b.createDate > a.createDate) ? -1 : 0)
        });
        setCampaignsList(_newCampaignList);
        setAlertOpen(true);
        setAlertTitle("");
        setAlertMessage(getDic("enviado-sucesso"));
      })
      .catch(err => {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setOpenLoading(false);
      })
      .finally(() => {
        setOpenLoading(false);
      })
  }

  const saveCampaign = async () => {
    var validation = validateForm(newCampaignForm);

    const _type = validation.form.find(f => {
      return f.dbReference === 'type';
    });
    if (_type.value === 'whatsapp') {
      const _templateNameIndex = validation.form.findIndex(f => {
        return f.dbReference === 'templateName';
      });
      if (!validation.form[_templateNameIndex].value || validation.form[_templateNameIndex].value === '') {
        validation.form[_templateNameIndex].error = true;
        validation.form[_templateNameIndex].errorMessage = getDic("preenchimento-obrigatorio");
        validation.isValid = false;
      }
    }

    const _message = validation.form.find(f => {
      return f.dbReference === 'template';
    });
    const _tagsIndex = validation.form.findIndex(f => {
      return f.dbReference === 'tags';
    });
    const _numberOfTags = getNumberOfTagsFromMessage(_message.value);
    var _tags = [];
    if (validation.form[_tagsIndex].value && validation.form[_tagsIndex].value !== '') {
      try {
        const _tagsSpace = validation.form[_tagsIndex].value.split(',');
        _tagsSpace.forEach(t => {
          if (t) {
            _tags.push(t.trim());
          } else {
            _tags.push(t);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    if (_tags.length !== _numberOfTags) {
      validation.form[_tagsIndex].error = true;
      validation.form[_tagsIndex].errorMessage = getDic("preenchimento-obrigatorio");
      validation.isValid = false;
    } else {
      validation.form[_tagsIndex].value = _tags;
    }

    if (validation.isValid) {

      var _newCampaign = convertFormToCampaignObject(newCampaignForm);

      setOpenLoading(true);
      setLoadingMessage(getDic("salvando"));

      _newCampaign.eventId = props.event.id;

      if (_newCampaign.type === 'whatsapp') {
        _newCampaign.typeUrl = whatsappTemplateList.find(t => t.value === _newCampaign.templateName).urlType;
      }

      var _error = false;
      const _newCampaignList = [];

      if (_newCampaign.id && (_newCampaign.id !== '' || _newCampaign.id > 0)) {
        await updateCampaignApi(_newCampaign)
          .then(res => {
            _newCampaignList.push(res);
          })
          .catch(err => {
            _error = true;
          })
      }
      else {
        await addNewCampaignApi(_newCampaign)
          .then(res => {
            _newCampaign.id = res.id;
            _newCampaignList.push(_newCampaign);
          })
          .catch(err => {
            _error = true;
          })
      }

      if (_error) {
        setAlertOpen(true);
        setAlertTitle(getDic("erro"));
        setAlertMessage(getDic("enviado-erro"));
        setOpenLoading(false);
      }
      else {
        campaignsList.forEach(item => {
          if (item.id !== _newCampaign.id) {
            _newCampaignList.push(item);
          }
        });
        _newCampaignList.sort((a, b) => {
          return (a.createDate > b.createDate) ? 1 : ((b.createDate > a.createDate) ? -1 : 0)
        });
        setCampaignsList(_newCampaignList);
        setAlertOpen(true);
        setAlertTitle("");
        setAlertMessage(getDic("enviado-sucesso"));
        resetCampaignForm();
        setOpenLoading(false);
        setOpenModal(false);
        setState(state === true ? false : true);
      }
    }
    else {
      setNewCampaignForm(validation.form);
    }
  }

  const openAdminCampaignPage = (campaign) => {
    props.setChosenCampaign(campaign);
    navigationHistoryService.setHistory(props.history, `/${props.event.id}/campaignadmin`);
  }

  return (
    <Container background="#e8eced" align="center">
      <FormRow align="center" background="#e8eced" margin="0px">
        <div>
          <MenuButton history={props.history} />
          <BackButton history={props.history} color="#212121">
            {getDic("voltar").toUpperCase()}
          </BackButton>
        </div>

        <FormRow
          minHeight="60px"
          align="center"
          background="#e8eced"
          margin="0px"
        />
        <FormRow
          minHeight="60px"
          align="flex-end"
          background="#e8eced"
          margin="0px"
          paddingTop="5px"
        >
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <Button
              color="lightgray"
              textColor="black"
              onClick={() => navigationHistoryService.setHistory(props.history, '/' + props.event.id + '/whatsappimporter')}
            >
              {`${getDic("importador")} Whatsapp`}
            </Button>
          </div>
          <div style={{ paddingRight: "10px", maxWidth: "400px" }}>
            <Button
              color="green"
              textColor="white"
              onClick={() => createNewCampaign()}
            >
              {`${getDic("cadastrar")} ${getDic("campanha")}`}
            </Button>
          </div>
        </FormRow>

        <FormColumn align="center" background="#e8eced" margin="0px" padding="5px">
          {campaignsList.length > 0 ? (
            <InfiniteScroll
              pageStart={0}
              dataLength={campaignsList.length}
              height={"79vh"}
            >
              <GridRow backgroundColor="#d9d9d9">
                <GridCell width="15%" border top first>
                  <TextMedium>ID</TextMedium>
                </GridCell>
                <GridCell width="36%" border top>
                  <TextMedium>{getDic("nome").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="13%" border top>
                  <TextMedium>{getDic("participantes").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="13%" border top>
                  <TextMedium>{getDic("enviado").toUpperCase()}</TextMedium>
                </GridCell>
                <GridCell width="13%" border top>
                  <TextMedium>{`${getDic("nao").toUpperCase()} ${getDic("enviado").toUpperCase()}`}</TextMedium>
                </GridCell>
                <GridCell width="10%" border top>
                  <TextMedium>{getDic("acao").toUpperCase()}</TextMedium>
                </GridCell>
              </GridRow>

              {campaignsList.map((item, i) => {
                return (
                  <GridRow
                    key={item.id}
                    autoColor={i}
                  >
                    <GridCell width="15%" border first>
                      <TextMedium>{item.id}</TextMedium>
                    </GridCell>
                    <GridCell width="36%" border>
                      <TextMedium>{item.name}</TextMedium>
                    </GridCell>
                    <GridCell width="13%" border>
                      <div style={{ textAlign: 'center' }}>
                        <TextMedium>{item.targets.length}</TextMedium>
                      </div>
                    </GridCell>
                    <GridCell width="13%" border>
                      <div style={{ textAlign: 'center' }}>
                        <TextMedium>{item.targetsSent.length}</TextMedium>
                      </div>
                    </GridCell>
                    <GridCell width="13%" border>
                      <div style={{ textAlign: 'center' }}>
                        <TextMedium>{item.targets.length - item.targetsSent.length}</TextMedium>
                      </div>
                    </GridCell>
                    <GridCell width="10%" border>
                      <EditIcon onClick={() => editCampaign(item)} style={{ cursor: "pointer" }} />
                      <CopyIcon onClick={() => copyCampaign(item)} style={{ cursor: "pointer" }} />
                      <DeleteIcon
                        onClick={() => {
                          setChosenCampaign(item);
                          setDialogConfirmOpen(true);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <SendIcon
                        onClick={() => {
                          openAdminCampaignPage(item);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </GridCell>
                  </GridRow>
                );
              })}
            </InfiniteScroll>
          ) : (
            <TextMedium>{getDic("pesquisa-sem-resultado")}</TextMedium>
          )}
        </FormColumn>

        <Modal
          isVideo
          height="80vh"
          open={openModal}
          closeButtonClick={e => {
            setOpenModal(false);
          }}
          onClose={ret => {
            setOpenModal(false);
          }}
        >
          <Container background="#e8eced" align="left" height="100%" >
            <FormColumn padding="5px" margin="0px" background="#e8eced">
              {newCampaignForm.map((item, i) => {
                if (item.dbReference === "targets") {
                  return null;
                }

                if (
                  item.dbReference === "url" &&
                  newCampaignForm.find(f => f.dbReference === "type").value === 'whatsapp'
                ) {
                  const _templateName = newCampaignForm.find(f => f.dbReference === "templateName").value;
                  if (_templateName && _templateName !== '') {
                    const _template = whatsappTemplateList.find(t => t.value === _templateName);
                    if (_template && !_template.hasUrl) {
                      return null;
                    }
                  }
                }

                if (i === 0) {
                  return (
                    <>
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <TextMedium>
                          {item.value > 0 ? (
                            `${getDic("editar")} ${getDic("campanha")}`
                          ) : (
                            `${getDic("nova")} ${getDic("campanha")}`
                          )}
                        </TextMedium>
                      </FormRow>
                    </>
                  )
                }
                else {
                  if (item.type === "select" && item.values) {
                    if (item.dbReference === 'templateName' && newCampaignForm[2].value !== 'whatsapp') {
                      return (null);
                    }
                    return (
                      <FormRow key={item.id} align="center" paddingTop="7px">
                        <SelectList
                          required={item.required}
                          error={item.error}
                          errorMessage={item.errorMessage}
                          title={item.name}
                          width="100%"
                          value={item.value}
                          menuItens={item.values}
                          onChange={async (event) =>
                            onChangeHandler(i, event.target.value)
                          }
                          multiple={Array.isArray(item.value) ? true : false}
                        />
                      </FormRow>
                    );
                  } else if (item.dbReference === 'template' && newCampaignForm[2].value === 'whatsapp') {
                    var _textArray = item.value ? item.value.split("\\n") : [];
                    return (
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <Card>
                          <TextSmall fontWeight="bold">
                            {getDic("exemplo")} {item.name}:
                          </TextSmall>
                          <br />
                          {_textArray.map(function (t, idx) {
                            return (
                              <span key={idx}>
                                {t}
                                <br />
                              </span>
                            )
                          })}
                        </Card>
                      </div>
                    );
                  }
                  else {
                    return (
                      <>
                        {item.dbReference === 'tags' && (
                          <>
                            <FormRow key='tags1' align="center">
                              <TextSmall>
                                {getDic("tag-instrucoes")}
                              </TextSmall>
                            </FormRow>
                            <FormRow key='tags2' align="center">
                              <TextSmall>
                                {`Tags ${getDic("dinamicas")}: ` + "${NOME}, ${CODIGOINTERNO}"}
                              </TextSmall>
                            </FormRow>
                          </>
                        )}
                        <FormRow key={item.id} align="center" paddingTop="7px">
                          <InputFieldOutlined
                            key={item.id}
                            required={item.required}
                            maxLength={item.maxLength}
                            error={item.error}
                            errorMessage={item.errorMessage}
                            value={item.value}
                            title={item.name}
                            type={item.type}
                            onChange={(event) => {
                              onChangeHandler(i, event.target.value)
                            }}
                            multiline={item.dbReference === 'template' ? true : false}
                            disabled={item.dbReference === 'template' && newCampaignForm[2].value === 'whatsapp' ? true : false}
                          />
                        </FormRow>
                      </>
                    );
                  }
                }
              })}
              <FormRow align="center" paddingTop="7px">
                <Button
                  text={getDic("salvar")}
                  onClick={() => saveCampaign()}
                />
              </FormRow>
            </FormColumn>
          </Container>
        </Modal>

        <Alert
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          onClick={() => setAlertOpen(false)}
          title={alertTitle}
          message={alertMessage}
        />

        <Dialog
          open={dialogConfirmOpen}
          onClose={() => setDialogConfirmOpen(false)}
          title={getDic("atencao")}
          textButtonYes={getDic("inativar")}
          onClickYes={() => {
            if (dialogConfirmKey && dialogConfirmKey != "" && dialogConfirmKey.toUpperCase() === "SIM") {
              inactiveCampaign();
              setDialogConfirmOpen(false);
              setDialogConfirmKey("");
            }
            else {
              setDialogConfirmKeyErrorMessage(true);
              setTimeout(() => {
                setDialogConfirmKeyErrorMessage(false);
              }, 3000); // 1 minuto = 60000
            }
          }}
          textButtonNo={getDic("cancelar")}
          onClickNo={() => {
            setDialogConfirmOpen(false);
            setDialogConfirmKey("");
          }}
        >
          <FormRow>
            <TextSmall>
              {getDic("confirmacao-de-acao")}
            </TextSmall>
          </FormRow>
          <FormRow align="center" paddingTop="5px">
            <InputFieldOutlined
              key="password"
              value={dialogConfirmKey}
              onChange={event => setDialogConfirmKey(event.target.value)}
            />
          </FormRow>
          {dialogConfirmKeyErrorMessage ? (
            <TextSmall color="red">{getDic("erro")}</TextSmall>
          ) : (null)}
        </Dialog>

        <Loading open={openLoading} message={loadingMessage} />
      </FormRow>
    </Container>
  )
}

function mapStateToProps(state) {
  return {
    event: state.event,
    user: state.user,
  }
}

const mapDispatchToProps = {
  setChosenCampaign
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsRegisterPage);
